export const blogs = [
    {
        id: "why-is-calcium-important-to-your-lawn",
        day: 3,
        month: "Feb",
        year: "2022",
        author: "Lawn Plan",
        authorImg: "/assets/images/icon.webp",
        tags: ["nutrients"],
        comments: [],
        img: "/assets/images/calcium.webp",
        imgAlt: "calcium periodic table on lawn",
        title: "Why is Calcium important to your lawn?",
        preview: "Calcium is critical to your lawn and is a key nutrient for holding both the plant and soil together...",
        content: (
            <>
            <p>
            Representing 4.1% of the earths crust and present in all forms of life.
            </p><p>
Calcium is critical to your lawn and is a key nutrient for holding both the plant and soil together. It’s able to do this because it has a positive charge of 2 that binds things together.
</p><p>
Calcium does not exist freely on earth due to its high volatility. It can be found in many natural compounds such as limestone.
</p><p>
In soil, calcium represents about 80% of it. Therefore, calcium is almost always available to the lawn. The availability of calcium can be categorised into 3 groups. Fixed (unavailable), exchangeable (slowly available) and solution (available).
</p><p>
Calcium can be converted (weathering) between these groups. It can also be leached from the soil or precipitated to other minerals.
</p><p>
In lawn fertilisers, soluble calcium is provided in a compound with other elements to reduce its volatility. Commonly with carbon as calcium carbonate (CaCO3) aka limestone.
</p><p>
Due to it being the dominant component in soil, it’s rarely deficient in lawns.
</p><p>
However, it’s availability in soil can impact the availability of other positively charged elements (cations) such as magnesium and potassium, since they’re fighting for the same bonds.
</p><p>
(We’ll do a separate post on cation exchange capacity CEC)
</p><p>
Calcium is the largest cation in the soil, so when it attaches soil particles to either side of it, it creates the greatest space for air and water. Due to its large size, it will replace smaller cations, leading to deficiencies if excessive.
</p><p>
A soil rich in calcium will be flocculated (airy and friable), whereas a soil rich in a smaller cation such as magnesium will cause the soil to collapse and become dense.
</p><p>
A flocculated soil improves the health of soil microbes giving them (and the roots) much needed air and water.
</p><p>
Healthy soil microbes improve the quality of your lawn by maintaining a natural chemical balance of the soil around them.
</p><p>
Typically, we want a calcium to magnesium ratio of 7:1.
</p><p>
At lawn plan, we only provide calcium (or other cations such as magnesium) in precise amounts if the ratio to other elements is sub-optimum.
</p><p>
Let us take care of the science for your lawn this year.</p>
            </>),
        url: "/blog/why-is-calcium-important-to-your-lawn",
    },
    {
        id: "why-is-potassium-important-to-your-lawn",
        day: 29,
        month: "Jan",
        year: "2022",
        author: "Lawn Plan",
        authorImg: "/assets/images/icon.webp",
        tags: ["nutrients"],
        comments: [],
        img: "/assets/images/potassium.webp",
        imgAlt: "potassium periodic table on lawn",
        title: "Why is Potassium important to your lawn?",
        preview: "Potassium is critical to your lawn and is a key nutrient for photosynthesis and respiration. Similar to phosphorus, it is required for the transfer of energy through the plant...",
        content: (
            <>
            <p>
            Representing 2.6% of the earths crust and present in all forms of life.
            </p><p>
Potassium is critical to your lawn and is a key nutrient for photosynthesis and respiration. Similar to phosphorus, it is required for the transfer of energy through the plant, but in much larger quantities. It’s also responsible for the movement of water in and out of the plant. This particular responsibility means a lawn with sufficient potassium will be able to withstand periods of stress, such as wear, heat, drought and frost.
</p><p>
Potassium does not exist freely on earth due to its high volatility. It can be found in many natural compounds such as granite.
</p><p>
In soil, the availability of potassium can be categorised into 3 groups. Fixed (unavailable) 90+%, exchangeable (slowly available) 1+% and solution (available) ~0.1%.
</p><p>
Lawns mainly consume the relatively limited solution potassium, but can also get limited amounts of potassium from the exchangeable type.
</p><p>
Potassium can be converted (weathering) between these groups, but the natural process is very slow. Furthermore, because the plant available form is soluble in water, it leaches at a significant rate. It’s no coincidence, the largest store of potassium are the oceans.
</p><p>
In lawn fertilisers, soluble potassium is provided in a compound with other elements to reduce its volatility. Commonly with sulphur as potassium sulphate (K2S04) and with chlorine as potassium chloride (KCl).
</p><p>
A lawn consumes potassium faster than the natural weathering process, therefore, it’s commonly deficient in lawns.
</p><p>
If deficient, lawns will be susceptible to disease and struggle through the summer and winter. Potassium compounds can be applied directly to the soil to compensate.
</p><p>
If potassium fertiliser has been applied without knowing how much already exists, it’s possible for levels to be deficient or excessive.
</p><p>
Excessive potassium isn’t really a problem for your lawn, however, it will quite quickly leach from the soil and contaminate groundwater…
</p><p>
At lawn plan, we provide potassium in precise amounts to keep up with your lawns consumption. If a deficiency is identified by a soil test. We will provide additional safe amounts over the course of the year to get back to the required level.
</p><p>
Let us take care of the science for your lawn this year.</p>
            </>),
        url: "/blog/why-is-potassium-important-to-your-lawn",
    },
    {
        id: "why-is-phosphorus-important-to-your-lawn",
        day: 26,
        month: "Jan",
        year: "2022",
        author: "Lawn Plan",
        authorImg: "/assets/images/icon.webp",
        tags: ["nutrients"],
        comments: [],
        img: "/assets/images/phosphorus.webp",
        imgAlt: "phosphorus periodic table on lawn",
        title: "Why is Phosphorus important to your lawn?",
        preview: "Phosphorus is critical to your lawn and is a building block of DNA creation. It is required for seed germination and is vital for early grass development...",
        content: (
            <>
            <p>
            Representing 0.1% of the earths crust and present in all forms of life.
            </p><p>
Phosphorus is critical to your lawn and is a building block of DNA creation. It is required for seed germination and is vital for early grass development. In an established lawn, phosphate is required at lower levels to maintain essential functions, such as energy, sugar and starch transfer through the plant. A lawn with sufficient phosphorus will have a strong root system.
</p><p>
Phosphorus does not exist freely on earth and only occurs naturally as Phosphate ([PO4]3−). Phosphate is found in organic tissue and as rock in the earths crust.
</p><p>
Fortunately, lawns are able to consume Phosphate directly from the soil.
</p><p>
As a result of phosphate being readily available in the soil and it’s ability to bind itself to soil particles (not leach), Phosphorous levels are normally sufficient for a healthy lawn.
</p><p>
If deficient, lawns will lack vigour and become sparse. Phosphate can be applied directly to the soil to compensate.
</p><p>
If phosphate fertiliser has been applied without knowing how much already exists, it’s possible for levels to become excessive.
</p><p>
Excessive phosphate causes other nutrients to be less available to your lawn, such as zinc and iron. This in turn causes your lawn to become unhealthy and start to die. It also carries the risk of contaminating groundwater, encouraging algae growth and stripping oxygen.
</p><p>
Some countries are now introducing strict phosphate controls to protect the environment and prolong the limited stocks of phosphate on earth.
</p><p>
At lawn plan, we only provide phosphorus when necessary, as identified by a soil test. If we need to provide phosphorous, we’ll do so in precise amounts to avoid losses, waste and ground water contamination.
</p><p>
Let us take care of the science for your lawn this year
                </p>
            </>),
        url: "/blog/why-is-phosphorus-important-to-your-lawn",
    },
    {
        id: "why-is-nitrogen-important-to-your-lawn",
        day: 25,
        month: "Jan",
        year: "2022",
        author: "Lawn Plan",
        authorImg: "/assets/images/icon.webp",
        tags: ["nutrients"],
        comments: [],
        img: "/assets/images/nitrogen.webp",
        imgAlt: "nitrogen periodic table on lawn",
        title: "Why is Nitrogen important to your lawn?",
        preview: "Nitrogen is critical to your lawn, but the abundant gaseous form present in the atmosphere is unfortunately unavailable to it...",
        content: (
            <>
            <p>
The most abundant element in Earth’s atmosphere and present in all forms of life.
</p><p>
Nitrogen is critical to your lawn, but the abundant gaseous form present in the atmosphere is unfortunately unavailable to it. Lawns can only consume ‘fixated’ atmospheric nitrogen, ‘mineralised’ oxidised nitrogen or direct ammonium from the soil. These processes are collectively known as the nitrogen cycle.
</p><p>
Fixated nitrogen is the result of atmospheric nitrogen being converted by living organisms/microbes in your soil to plant available ammonium.
</p><p>
Mineralised nitrogen is the result of the oxidised nitrogen (organic matter/lawn feed/fertiliser) being converted by living organisms in your soil to plant available ammonium. These organisms are efficient in soil temperatures above 15c.
</p><p>
Direct ammonium can be applied as non-organic lawn feed and is instantly available to your lawn. This is a good choice when soil temperatures are below 15c and microbe activity is reduced.
</p><p>
All plant available nitrogen is extremely volatile as a consequence of the nitrogen cycle. Slow release lawn feeds combat this by inhibiting the nitrogen cycle. Once nitrogen is plant available, it is subject to volatilisation back to the atmosphere, denitrification and leaching.
</p><p>
The microbes in your soil play a crucial roll in the health of your lawn and the availability of the most critical element.
</p><p>
At lawn plan, we aim to increase soil microbe activity to enable greater nitrogen fixation and mineralisation. We also look to provide additional oxidised nitrogen in precise amounts to make up any deficiencies whilst avoiding losses, waste and ground water contamination.
</p><p>
Let us take care of the science for your lawn this year.
                </p>
            </>),
        url: "/blog/why-is-nitrogen-important-to-your-lawn",
    },
    {
        id: "mowing-schedule",
        day: 12,
        month: "Jan",
        year: "2022",
        author: "Lawn Plan",
        authorImg: "/assets/images/icon.webp",
        tags: ["mowing"],
        comments: [],
        img: "/assets/images/grass-clippings.webp",
        imgAlt: "fresh grass clippings in hand",
        title: "Mowing schedule",
        preview: "How often you mow your lawn depends on a number of factors, however, there is a single rule you should always follow...",
        content: (
            <>
                <p>
                    How often you mow your lawn depends on a number of factors, however, there is a single rule you should always follow...
                </p>
                <p>
                    <b>Never mow more than 1/3rd off the height of your lawn.</b>
                </p>
                <p>
                    Commonly referred to as the 1/3rd rule, it encourages your grass to grow thickly and reduces the risk of shocking it (yellowing/stunting). If for example, you decide to mow your lawn at 20mm, you should always mow it before it reaches 30mm in height. This means no more than 10mm (1/3rd of 30mm) is mowed off.
                </p>
                <p>
                    If you wish to reduce the height of cut, do so in incremements over a number of days ensuring you never exceed the 1/3rd rule. 
                </p>
                <p>
                    Mowing at shorter heights, warmer temperature, greater sun exposure, optimum nutrients and optimum water will all cause grass to grow quicker and require mowing at more frequent intervals. This could be as often as every other day in the height of summer. A longer lawn could get away with being mowed every week.
                </p>
                <p>
                    Ultimately, you can never mow too much, and the more you do, the thicker your lawn will become.
                </p>
            </>),
        url: "/blog/mowing-schedule",
    },
    {
        id: "soil-nutrients",
        day: 11,
        month: "Jan",
        year: "2022",
        author: "Lawn Plan",
        authorImg: "/assets/images/icon.webp",
        tags: ["nutrients"],
        comments: [],
        img: "/assets/images/level-soil.webp",
        imgAlt: "Garden soil levelled before seeding",
        title: "Soil nutrients",
        preview: "There are a number of nutrients in soil. Some have no real effect on grass, some are beneficial to grass and some are critical to grass...",
        content: (
            <>
                <p>
                    There are a number of nutrients in soil. Some have no real effect on grass, some are beneficial to grass and some are critical to grass.
                </p>
                <p>
                    Nitrogen (in it's nitrate and ammonium form) is critical to your grasses ability to grow, an average UK lawn will consume almost half a kilo per month throughout the growing season. Nitrogen breaks down via the nitrogen cycle and is only available to grass for a limited time.
                </p>
                <p>
                    Phosphorous is critical to your grasses ability to develop roots. Soil is able to retain the nutrient fairly well, however excessive levels can lead to weed prevalance.
                </p>
                <p>
                    Potassium is critical to your grasses health and enables the formation of strong cell membranes which in turn increases resistance to stress from cold, heat, drought and disease. Soil struggles to retain this nutrient.
                </p>
                <p>
                    Magnesium is beneficial to your grasses health and helps it survive over the winter period. It also enables the consumption of many other nutrients. A deficiency would result in yellowing.
                </p>
                <p>
                    Calcium is beneficial to your grasses health and helps it survive over the summer period. It also enables the consumption of many other nutrients. A deficiency would result in paleness.
                </p>
                <p>
                    Iron is critical to your grasses ability to photosynthesise and enables the production of chlorophyll. An increased level of iron will give the lawn a darker shade of green. A deficiency would result in yellowing.
                </p>
                <p>
                    Copper, Boron, Zinc, Magnasese are normally at good levels in UK soils so ammendment is rarely necessary. They contribute to your grasses ability to grow and survive.
                </p>
                <p>
                    There are other trace elements that have no real noticeable effect on grass, certainly not at levels normal of the UK.
                </p>
            </>),
        url: "/blog/soil-nutrients",
    },
]
