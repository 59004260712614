import { useEffect, useRef } from "react";
import { blogs } from "../data/blogs";
import { getAnalytics, logEvent } from "firebase/analytics";
import { Helmet } from "react-helmet";
import {  Box, Button, Typography } from "@mui/material";
// import AddressAutocomplete from "./AddressAutocomplete";

export default function Home() {
    
    const analytics = getAnalytics();

    //Get the element you want to track with useRef
    const splashTarget = useRef(null);

    //Use the observer inside the component you want to track scroll
    useEffect(() => {
        const opts = {
                root: null,
                rootMargin: '0px',
                threshold: 0
        }
        const callback = list => {
        list.forEach(entry => {
            if(entry.isIntersecting) {
                logEvent(analytics, 'home.scroll', {"id": entry.target.id})
            }
        })
        }
        const observerScroll = new IntersectionObserver(callback, opts)

        observerScroll.observe(splashTarget.current);
    }, [analytics])

   function contactUs() {
    window.location.href = 'https://tawk.to/chat/6256841cb0d10b6f3e6d53de/1g0gv848j';
   } 

  return (
    <div className="App">
        <Helmet>
            <title>Lawn Plan</title>
            <meta name="description" content="Bespoke nutrients for your lawn, delivered to your door."/>            
            <meta property="og:title" content="Lawn Plan" />
            <meta property="og:description" content="Bespoke nutrients for your lawn, delivered to your door." />
            <meta property="og:image" content="/assets/images/logo.png" />
            {/* <script type="text/javascript" src="/yopta.js"/> */}
        </Helmet>          
                <Box id="splash"ref={splashTarget} display="flex" flexDirection="column" gap="10px" justifyContent="center" alignItems="center"
                style={{height: "80vh", backgroundImage: "url(assets/images/splash.webp)",
         backgroundSize: "cover", backgroundPosition: "center"}}>
                    <Typography variant="h3" color="white" align="center">Coming soon</Typography>
                    <Box display="flex" flexDirection="row" gap="10px">
                        <Button variant="contained" onClick={contactUs}>
                            Contact us
                        </Button>
                    </Box>
                </Box>


      

    </div>
  );
}
