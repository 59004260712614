import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { getAnalytics, logEvent } from "firebase/analytics";

export function FirebaseAnalytics() {
    let location = useLocation();
    useEffect(() => {
        const analytics = getAnalytics();
        const page_path = location.pathname; 
        logEvent(analytics, "screen_view", { page_path });
    }, [location]);
    return null;
}