import Header from "./Header";
import Home from './Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Blog from './Blog';
import Careers from './Careers';
import Company from './Company';
import Help from './Help';
import Privacy from './Privacy';
import Terms from './Terms';
import BlogSingle from './BlogSingle';
import Plan from './Plan';

import Checkout from './Checkout';
import { FirebaseAnalytics } from './FirebaseAnalytics'
import Analysis from './Analysis';
import { Helmet } from 'react-helmet';
import MyLawn from "./MyLawn";
import MyPlan from "./MyPlan";
import Auth from "./Auth";
import Footer from "./Footer";
// import PromoBanner from "./PromoBanner";
import CheckoutDetails from "./CheckoutDetails";


export default function App() {
    return (
    <>
        {/* <PromoBanner/>    */}
        <Header/>       
        <Helmet>
            <title>Lawn Plan</title>
            <meta name="description" content="Bespoke nutrients for your lawn, delivered to your door."/>               
            <meta property="og:title" content="Lawn Plan" />
            <meta property="og:description" content="Bespoke nutrients for your lawn, delivered to your door." />
            <meta property="og:image" content="/assets/images/logo.png" />
            <meta property="og:type" content="website" />
        </Helmet>
        <BrowserRouter>        
            <FirebaseAnalytics/>
            <Routes>
                <Route path="/" element={<Home/>}/>
               
                <Route path="/privacy" element={<Privacy/>}/>
                <Route path="/terms" element={<Terms/>}/>
            </Routes>
        </BrowserRouter>
        <Footer/>
    </>
    )
}