import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';

export default function Footer(){
    return (
        <footer class="xs-footer-sec">
    <div class="container">    
        <div class="footer-item">
            <div class="row">
                <div class="col-lg-3">
                    <div class="widgets">
                        <div class="footer-logo">
                            <a href="/"><img src="/assets/images/logo-white.png" alt="Lawn Plan logo"/> </a>
                        </div>
                        <p>
                            All your lawn needs to thrive. Let us take the guess work out, using the latest advances in agronomy and horticulture.
                        </p>
                        <ul class="footer-social">
                            <li>
                                <a href="https://www.facebook.com/LawnPlan"><FacebookIcon/></a>
                            </li>
                            <li>
                                <a href="https://twitter.com/lawn_plan"><TwitterIcon/> </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/lawn_plan/"><InstagramIcon/></a>
                            </li>
                        </ul>
                    </div>
                </div>
                
            </div>
        </div>
        <div class="copyright-content">
            <div class="row">
            <div class="col-md-6">
                <p> © 2022 Origital Ltd</p>
            </div>
            <div class="col-md-6">
                <ul class="footer-bottom-menu">
                    <li>
                        <a href="/terms">Terms of Service </a>
                    </li>
                    <li>
                        <a href="/privacy">Privacy Policy</a>
                    </li>
                </ul>
            </div>
            </div>
        </div>
    </div>
</footer>
    )
}