import axios from "axios";

const url = `${process.env.REACT_APP_FUNCTION_URI}/api`;

const getConfig = async (getAccessTokenSilently) =>{
    const accessToken = await getAccessTokenSilently();
    return {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        }
    }
}

export async function getMyLawn(getAccessTokenSilently){    
    const config = await getConfig(getAccessTokenSilently);
    return await axios.get(`${url}/my-lawn`, config);
}

export async function getMyPlan(getAccessTokenSilently){    
    const config = await getConfig(getAccessTokenSilently);
    return await axios.get(`${url}/my-plan`, config);
}

export async function addAddress(id){
    return await axios.post(`${url}/add-address`, {placeId: id})
}

export async function updateAddress(id, lat, lng){
    return await axios.patch(`${url}/update-address`, {id: id, lat: lat, lng: lng})
}

export async function wrongLocation(id){
    return await axios.patch(`${url}/wrong-location`, {id: id})
}

export async function getLawn(addressId){
    return await axios.get(`${url}/getLawn?addressId=${addressId}`)
}

export async function getPlan(addressId){
    return await axios.get(`${url}/getPlan?addressId=${addressId}`)
}