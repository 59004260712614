import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { useAuth0 } from '@auth0/auth0-react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const pages = [
    { 
        display: 'Home',
        path: '/'
    },
    { 
        display: 'Get Started',
        path: '/#pricing'
    },
    { 
        display: 'Pricing',
        path: '/#pricing'
    },
    { 
        display: 'How It Works',
        path: '/#how'
    },
    { 
        display: 'Blog',
        path: '/blog'
    }];
const settings = [
  { 
      display: 'Login',
      path: '/login',
      auth: false,
  },
  { 
      display: 'My Lawn',
      path: '/my-lawn',
      auth: true,
  },
  { 
      display: 'My Plan',
      path: '/my-plan',
      auth: true,
  },  
  { 
      display: 'Logout',
      path: '/logout',
      auth: true,
  }];

export default function Header() {  
  const { isAuthenticated, user, loginWithRedirect, logout} = useAuth0();

    const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleNav = (path) => {
      handleCloseNavMenu();
      window.location.href = path;
  }

  const handleSetting = (path) => {
    handleCloseUserMenu();
    if(path === "/login" || path === "/sign-up")
    {
      loginWithRedirect();
    }
    if(path === "/logout")
    {
      logout();
    }
    else{
      window.location.href = path;
    }
}

    return (
    <AppBar position="sticky" style={{backgroundColor: "white"}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: { md: 'flex' } }}
          >
            <a href="/">
              <img src="/assets/images/logo.png" alt="logo" style={{height: "50px"}}/>
            </a>
          </Typography>

          
          
          
        </Toolbar>
      </Container>
    </AppBar>
        )
}