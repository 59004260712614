import {render} from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from 'firebase/app';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './components/App';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@emotion/react';

const firebaseConfig = {
  apiKey: "AIzaSyBbf9kp6gRL9HEvt-5vyFtp7rxP7xTtlpo",
  authDomain: "lawnplan.firebaseapp.com",
  projectId: "lawnplan",
  storageBucket: "lawnplan.appspot.com",
  messagingSenderId: "435248119788",
  appId: "1:435248119788:web:72b43385286fe417820ac9",
  measurementId: "G-860RJZW1PN"
};
  
initializeApp(firebaseConfig);

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#008435',
    },
    secondary: {
      main: '#1ba8d2',
    },
  },  
  typography: {
    fontFamily: 'Poppins',
    button: {
      //textTransform: "none"
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root:{
          //padding: "20px",
        }
      }
     
    }
  }
});

render(
  <Auth0Provider
  domain="lawnplan.eu.auth0.com"
  clientId={process.env.REACT_APP_AUTH_KEY}
  redirectUri={window.location.origin + "/my-lawn"}
  audience="https://lawnplan.api"
  useRefreshTokens={true}
  cacheLocation="localstorage"
  >
    <ThemeProvider theme={theme}>
      <App/>
    </ThemeProvider>
  </Auth0Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
